<!-- 2022.11.4  @xjm 顺序签署 详情卡片 -->
<template>
  <div class="detail" v-for="(item, index) in signList" :key="index">
    <div class="detail-head flex-layout">
      <span>{{ item.type ? '接收方' : '发起方' }}</span>
      <div class="isSign" v-if="item.signatoryType !== 1">
        <span v-if="item.operatorNodeStatus">经办人签字</span>
        <span v-if="item.legalPersonNodeStatus">法定代表人签字</span>
        <span v-if="item.companyNodeStatus">企业签章</span>
      </div>
    </div>
    <div class="detail-content flex-layout">
      <template v-if="!item.type">
        <section>
          <span class="label">企业名称：</span>
          <p>{{ item.name }}</p>
        </section>
      </template>
      <template v-else>
        <template v-if="item.signatoryType">
          <section>
            <span class="label">个人姓名：</span>
            <p>{{ item.name }}</p>
          </section>
          <section>
            <span class="label">联系方式：&nbsp;&nbsp;</span>
            <p>{{ item.operatorContact }}</p>
          </section>
          <section class="opcity0">
            <span class="label">联系方式：&nbsp;&nbsp;</span>
            <p>xxxxx</p>
          </section>
        </template>
        <template v-else>
          <section>
            <span class="label">企业名称：</span>
            <p>{{ item.name }}</p>
          </section>
          <section>
            <span class="label">经办人姓名：</span>
            <p>{{ item.operatorName }}</p>
          </section>
          <section>
            <span class="label">联系方式：</span>
            <p>{{ item.operatorContact }}</p>
          </section>
        </template>
      </template>
    </div>
  </div>
</template>
<script setup>
import { defineProps } from 'vue'
defineProps(['signList'])
</script>
<style lang="less" scoped>
.detail {
  border: 1px solid #dcdee0;
  margin-bottom: 17px;
  &-head {
    padding: 12px 24px;
    background: #f5f6f7;
    border-radius: 2px 2px 0px 0px;
    > span {
      margin-right: 16px;
      font-weight: 500;
      font-size: 14px;
      color: #131314;
    }
    .isSign {
      span {
        margin-right: 8px;
        padding: 3px 8px;
        font-size: 12px;
        color: #646566;
        background: linear-gradient(270deg, #dcdee0 0%, #e9eaed 100%);
      }
    }
  }
  &-content {
    padding: 24px;
    justify-content: space-between;

    > section {
      display: flex;
      text-overflow: ellipsis;
      p {
        width: 18em;
        font-size: 14px;
        font-weight: 500;
        color: #131314;
      }
      .label {
        color: rgba(0, 0, 0, 0.65);
      }
    }
  }
  .opcity0 {
    opacity: 0;
  }
}
</style>
